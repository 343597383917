import React, { useState, useEffect, useRef } from "react"
import Layout from "../components/Layout/Layout"
import Seo from "../components/Core/Seo"
import { graphql } from "gatsby"
import Intro from "../components/Quiz/Intro"
import ProgressBar from "../components/Quiz/ProgressBar"
import QuizTopic from "../components/Quiz/QuizTopic"
import IntroMobileAnimation from "../resources/animation/quiz/intro-mobile.json"
import HairMobileAnimation from "../resources/animation/quiz/hair-mobile.json"
import ScalpMobileAnimation from "../resources/animation/quiz/scalp-mobile.json"
import LifeStyleMobileAnimation from "../resources/animation/quiz/lifestyle-mobile.json"
import IntroAnimation from "../resources/animation/quiz/intro.json"
import HairAnimation from "../resources/animation/quiz/hair.json"
import ScalpAnimation from "../resources/animation/quiz/scalp.json"
import LifeStyleAnimation from "../resources/animation/quiz/lifestyle.json"
import ThankYouMobileAnimation from "../resources/animation/quiz/thank-you-mobile.json"
import ThankYouAnimation from "../resources/animation/quiz/thank-you.json"
import { isBrowser } from "../services/core/browser"
import { navigate } from "gatsby"
import OverlayAnimation from "../components/Quiz/OverlayAnimation"
import { lambdaCall } from "../services/core/lambdaCall"
import { createGlobalStyle } from "styled-components"
import breakpoints from "../styles/breakpoints"

const serialize = require("form-serialize")

const GlobalStyle = createGlobalStyle`
  div.acsb-trigger {
    @media (min-width: ${breakpoints.sm}) and (max-width: 768px) {
      inset: auto 33px 33px auto !important;
    }
  
    @media (min-width: ${breakpoints.air}) {
      inset: auto 33px 33px auto !important;
    }
  
    @media (min-width: ${breakpoints.ipad}) {
      inset: auto 34px 34px auto !important;
    }
  }
`

const QuizPage = ({ location, data }) => {
  const [quizIndex, setQuizIndex] = useState(0)
  const [sectionIndex, setSectionIndex] = useState(0)
  const [quizAnswers, setQuizAnswers] = useState({})
  const [overlayVisible, setOverlayVisible] = useState(true)
  const [overlayIndex, setOverlayIndex] = useState(0)
  const [width, setWidth] = useState(0)
  const [quizFlow, setQuizFlow] = useState([])
  const path = location.pathname
  let formRef = useRef(null)
  const { page } = data
  const animations = [
    { mobile: IntroMobileAnimation, desktop: IntroAnimation },
    { mobile: HairMobileAnimation, desktop: HairAnimation },
    { mobile: ScalpMobileAnimation, desktop: ScalpAnimation },
    { mobile: ThankYouMobileAnimation, desktop: ThankYouAnimation },
  ]

  const { metaTitle, metaDescription, socialMediaImage, blocks } = page

  let sharingImage = false

  if (socialMediaImage && socialMediaImage.file) {
    sharingImage = socialMediaImage.file.url
  }

  const handleOverlayIndex = () => {
    let nextIndex = quizIndex

    if (nextIndex < animations.length) {
      setOverlayIndex(nextIndex)
      setOverlayVisible(true)
    } else {
      setOverlayVisible(false)
    }
  }

  const getWindowWidth = () => {
    setWidth(window.innerWidth)
  }

  useEffect(() => {
    if (isBrowser()) {
      getWindowWidth()
      window.addEventListener("resize", getWindowWidth)
    }
    return () => {
      window.removeEventListener("resize", getWindowWidth)
    }
  })

  // to do - klaviyo submit

  const handleSubmit = () => {
    if (formRef) {
      let data = serialize(formRef, { hash: true })
      let selecteds = formRef.querySelectorAll("input:checked")

      let recObj = {
        "Set A": 0,
        "Set B": 0,
        "Set C": 0,
      }

      selecteds.forEach((field) => {
        let recommendation = field.parentElement.getAttribute(
          "data-recommendation"
        )

        let weight = field.parentElement.getAttribute("data-weight") || 0

        if (recommendation === "None" || weight === "") return


        let recArray = recommendation.split(" + ")


        /* --- Add up our set obj --- */
        recArray.forEach((set) => {
          if (weight) {
            recObj[set] = recObj[set] + parseInt(weight)
          } else {
            recObj[set] = recObj[set];
          }
        })
      })

      let rec = "a"
      // Form query string for results
      if (recObj["Set A"] < recObj["Set B"]) {
        rec = "b"
      }

      if (
        recObj["Set A"] < recObj["Set C"] &&
        recObj["Set B"] < recObj["Set C"]
      ) {
        rec = "c"
      }

      data.recommended = "set_" + rec
      data.resultUrl = process.env.WEBSITE_URL + "/quiz-results/?t=" + btoa(JSON.stringify(data))

      lambdaCall({
        url: "quiz",
        method: "POST",
        body: JSON.stringify(data),
      })
        .then((response) => {
          return Promise.all([response.status, response.text()])
        })

      navigate("/quiz-results/?t=" + btoa(JSON.stringify(data)))
    }
  }


  return (
    <Layout quiz={true} footer={false}>
      <GlobalStyle />
      <Seo
        path={path}
        title={metaTitle}
        description={metaDescription}
        fullTitle={false}
        image={sharingImage}
      />
      <ProgressBar
        blocks={blocks}
        sectionIndex={sectionIndex}
        quizIndex={quizIndex}
        quizFlow={quizFlow}
      />
      <OverlayAnimation
        animations={animations}
        visible={overlayVisible ? 1 : 0}
        first={overlayIndex === 0 ? 1 : 0}
        setOverlayVisible={setOverlayVisible}
        handleSubmit={handleSubmit}
        width={width}
        overlayIndex={overlayIndex}
        resultCopy={page.thankYou}
        isLastIndex={quizIndex > blocks.length}
      />
      {quizIndex === 0 ? (
        <React.Fragment>
          <Intro {...page} setQuizIndex={setQuizIndex} />
        </React.Fragment>
      ) : (
        <React.Fragment>
          <form id={"quiz-form"} ref={(el) => (formRef = el)}>
            {blocks.map((topic, index) => {
              return (
                <QuizTopic
                  {...topic}
                  setQuizIndex={setQuizIndex}
                  key={index}
                  quizIndex={quizIndex}
                  quizAnswers={quizAnswers}
                  setQuizAnswers={setQuizAnswers}
                  setOverlayVisible={setOverlayVisible}
                  handleOverlayIndex={handleOverlayIndex}
                  sectionIndex={sectionIndex}
                  setSectionIndex={setSectionIndex}
                  topics={blocks}
                  visible={index + 1 === quizIndex && !overlayVisible}
                  quizFlow={quizFlow}
                  setQuizFlow={setQuizFlow}
                />
              )
            })}
          </form>
        </React.Fragment>
      )}
    </Layout>
  )
}

export default QuizPage

export const pageQuery = graphql`
  query QuizpageQuery {
    page: contentfulQuiz(slug: { eq: "quiz" }) {
      id
      metaTitle
      metaDescription
      socialMediaImage {
        file {
          url
        }
      }
      introTrackingLabel
      introCopy {
        raw
      }
      quizStartButtonText
      disclaimerHeadline
      disclaimerCopy
      thankYou {
        raw
      }
      doctor {
        content {
          raw
        }
        image {
          gatsbyImageData(width: 160, quality: 80)
          title
        }
      }
      blocks {
        ... on ContentfulQuizTopic {
          __typename
          title
          sections {
            ... on ContentfulQuizChoiceInput {
              id
              __typename
              question
              questionWeight
              selectionsIdentifier
              subtext
              trackingLabel
              type
              whyWeAsk {
                childMarkdownRemark {
                  html
                }
              }
              options {
                id
                icon {
                  file {
                    url
                  }
                }
                setRecommendation
                skipToQuestion
                skipToQuestionWeight
                text
                speedBumpText {
                  raw
                }
                speedBumpExit
                speedBumpAccept
              }
            }
            ... on ContentfulTextInput {
              id
              __typename
              inputIdentifier
              inputPlaceholderText
              question
              subtext
              trackingLabel
              whyWeAsk {
                childMarkdownRemark {
                  html
                }
              }
            }
          }
        }
      }
    }
  }
`
